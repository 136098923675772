<template>
  <div id="main-content">
    <Tracking />
  </div>
</template>

<script>
import Tracking from "../components/tracking/Tracking";

export default {
  name: "TrackingView",
  components: {
    Tracking,
  },
};
</script>
