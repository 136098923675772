<template>
  <div id="main-content">
    <Home />
  </div>
</template>

<script>
import Home from "../components/Home";

export default {
  name: "HomeView",
  components: {
    Home,
  },
};
</script>
