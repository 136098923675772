<template>
  <div class="heading">
    <h2>{{ $t("trackingStages") }}</h2>
  </div>
  <div class="events">
    <div class="events__item" v-for="item in item.ackHistory" :key="item.jysId">
      <Event
        :item="item"
        :parse-time="parseTime"
        :parseTrackingDescription="parseTrackingDescription"
      />
    </div>
  </div>
</template>

<script>
import Event from "./Event";

export default {
  name: "Events",
  props: ["item", "parseTime", "parseTrackingDescription"],
  components: {
    Event,
  },
};
</script>

<style lang="sass" scoped>
@import "src/styles/variables"

.heading
  padding: 0 10px 10px

.events
  border-left: 4px solid $colorMain
  border-left-style: dotted
  padding-right: 14px
  padding-left: 20px
  margin: 0 10px

  &__item:first-of-type .track-item
    padding-top: 0

  &__item:last-of-type .track-item
    padding-bottom: 0
</style>
