<template>
  <SearchForm />
</template>

<script>
import SearchForm from "./shared/SearchForm";

export default {
  name: "Home",
  components: { SearchForm },
  data() {
    return {
      trackingCode: "",
    };
  },
};
</script>
