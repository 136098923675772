import Cookies from "universal-cookie";

const cookies = new Cookies();

export const setCookieValue = (cookie, value) => {
  cookies.set(cookie, value, { path: "/" });
};

export const getCookieValue = (cookie) => {
  return cookies.get(cookie);
};
