<template>
  <div>
    <div class="title">
      <h1>{{ $t("trackingTitle") }}</h1>
    </div>
    <div class="search-form">
      <div class="search-form__heading">
        <label for="tracking-id-input">
          <strong>{{ $t("itemId") }}</strong>
        </label>
      </div>
      <form @submit.prevent class="search-form__form">
        <input
          id="tracking-id-input"
          class="search-form__input"
          v-model="trackingId"
          :placeholder="$t('itemId')"
          maxlength="40"
          required
        />
        <button
          :aria-disabled="this.trackingId.trim().length === 0"
          @click="searchSubmit"
          class="search-form__button"
        >
          <font-awesome-icon
            type="button"
            class="search-form__icon"
            :icon="['fas', 'arrow-right']"
          />
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import router from "../../router";

export default {
  name: "SearchForm",
  data() {
    return {
      trackingId: "",
    };
  },
  methods: {
    searchSubmit: function () {
      const id = this.trackingId.trim();
      if (id.length > 0 && this.$route.params.id !== id) {
        router.push(`/${this.$t("trackingUrlPath")}/${encodeURIComponent(id)}`);
        this.trackingId = "";
      }
    },
  },
};
</script>

<style scoped lang="sass">
@import "../../styles/variables"
.title h1
  margin-top: 10px

.search-form
  &__heading
    padding: 5px

  &__form
    display: flex
    justify-content: center

  &__input
    border-radius: 5px
    border: 1px solid #dedede
    padding: 0.75rem
    flex: 1

  &__button
    border: none
    background: none
    padding: 0

  &__icon
    color: $colorMain
    cursor: pointer
    padding: 0.75rem
</style>
