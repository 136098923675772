<template>
  <div class="header">
    <img
      class="header__image"
      alt="Jakeluyhtiö suomi logo"
      src="@/assets/images/jys_logo_small.png"
    />
    <div class="header__currentLanguage">
      <button
        v-on:click="this.showLanguageSelection = !this.showLanguageSelection"
      >
        {{ this.languages.find((lang) => lang.short === $i18n.locale).long }}
      </button>
    </div>
    <div
      id="localeSelector"
      v-if="showLanguageSelection"
      class="header__localeSelector"
    >
      <template v-for="language in languages" :key="language.short">
        <button v-on:click="changeLanguage(language.short)">
          {{ language.long }}
        </button>
      </template>
    </div>
  </div>
</template>

<script>
import { setCookieValue } from "../utils/cookies";
import { setTrackingPath, setPageTitle } from "../utils/language";

export default {
  name: "Header",
  data() {
    return {
      showLanguageSelection: false,
      languages: [
        { short: "fi", long: "Suomi" },
        { short: "en", long: "English" },
        { short: "sv", long: "Svenska" },
      ],
    };
  },
  methods: {
    changeLanguage: function (lang) {
      this.$i18n.locale = lang;
      this.showLanguageSelection = false;
      setCookieValue("lang", lang);

      const trackingId = this.$route.params.id;
      setTrackingPath(trackingId, lang);
      setPageTitle();
    },
  },
  mounted() {
    const closeLocaleSelection = (evt) => {
      const parentClass = evt.target.parentElement.className;

      if (
        parentClass === "header__currentLanguage" ||
        parentClass === "header__localeSelector"
      ) {
        return;
      }

      this.showLanguageSelection = false;
    };

    window.addEventListener("click", closeLocaleSelection);
  },
};
</script>

<style lang="sass">
@import "../styles/variables"

.header
  display: flex
  flex-direction: row
  padding: 0.75rem
  box-shadow: $shadow1
  border-top: 3px solid $colorMain
  box-sizing: border-box
  justify-content: space-between
  background: #FFFFFF
  align-items: center

  button
    background: #ffffff
    border-radius: 5px
    border: none
    color: $colorFontDefault
    font-weight: 400

    &:hover
      cursor: pointer

  &__currentLanguage button
    font-size: 1rem
    padding-right: 20px

  &__localeSelector
    display: flex
    flex-direction: column
    position: absolute
    top: 70px
    right: 10px
    border: none
    border-radius: 5px
    box-shadow: $shadow1

    button
      padding: 20px

      &:hover
        background: $colorMain
        color: #ffffff
</style>
