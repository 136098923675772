<template>
  <div class="selector">
    <div class="selector__desc">
      <div>
        <strong>
          {{ data[0].documentId }}
        </strong>
      </div>
      <div>
        <span v-html="$t('multipleItemsFound', { amount: data.length })" />
      </div>
    </div>
    <div
      class="selector__item"
      v-for="(item, index) in data"
      :key="item.jysId"
      v-on:click="selectItem(index)"
      tabindex="0"
    >
      <div v-if="item.ackHistory[0].ackTime">
        <span>{{ parseTime(item.ackHistory[0].ackTime) }}</span>
      </div>
      <div>
        <strong>{{
          parseTrackingDescription(item.ackHistory[0].trackingPageDescriptions)
        }}</strong>
      </div>
      <div>
        <template v-if="item.postalCode && item.city">
          <span>{{ $t("targetPostalCode") }}: </span>
          <span>{{ item.postalCode }}, {{ item.city.toUpperCase() }}</span>
        </template>
        <template v-if="item.postalCode && !item.city">
          <span>{{ $t("targetPostalCode") }}: </span>
          <span>{{ item.postalCode }}</span>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import moment from "moment";

export default {
  name: "ItemSelector",
  props: ["data", "selectItem"],
  methods: {
    parseTime: function (time) {
      return moment(time).format("DD.MM.YYYY H:mm");
    },
    parseTrackingDescription: function (descriptions) {
      return descriptions[this.$i18n.locale];
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../../styles/variables"

.selector
  &__item
    padding: 1rem
    background: rgba(0, 0, 0, 0.02)
    margin: 0.5rem 0
    border-radius: 5px
    border-left: 5px solid $colorMain
    cursor: pointer

    div
      padding: 5px 0

    &:hover
      background: $colorMain
      color: #ffffff

  &__desc
    padding: 16px 8px

    div
      padding: 5px 0
</style>
