<template>
  <div>
    <div v-if="multiple">
      <div tabindex="0" class="return" @click="backToSelection">
        {{ $t("goBack") }}
      </div>
    </div>
    <Info :item="data" />
    <Events
      :item="data"
      :parseTime="parseTime"
      :parseTrackingDescription="parseTrackingDescription"
    />
  </div>
</template>

<script>
import Info from "./Item/Info";
import Events from "./Item/Events";
import moment from "moment";

export default {
  name: "Item",
  components: {
    Info,
    Events,
  },
  props: ["data", "multiple", "backToSelection"],
  methods: {
    parseTime: function (time) {
      return moment(time).format("DD.MM.YYYY H:mm");
    },
    parseTrackingDescription: function (descriptions) {
      return descriptions[this.$i18n.locale];
    },
  },
};
</script>

<style lang="sass" scoped>
@import "../../styles/variables"

.return
  margin-top: 32px
  color: $colorMain

  &:hover
    cursor: pointer
</style>
