import i18n from "../i18n";

export const langOptions = [
  { locale: "fi", trackingPath: "seuranta" },
  { locale: "en", trackingPath: "tracking" },
  { locale: "sv", trackingPath: "uppfoljning" },
];

export const getLocale = (path) => {
  const lang = langOptions.find((l) => l.trackingPath === path);

  return lang ? lang.locale : lang;
};

export const getTrackingPath = (locale) => {
  const lang = langOptions.find((l) => l.locale === locale);

  return lang ? lang.trackingPath : lang;
};

export const setTrackingPath = (trackingId, lang) => {
  const path = getTrackingPath(lang);

  if (trackingId) {
    return window.history.replaceState(null, null, `/${path}/${trackingId}`);
  } else {
    return history.replaceState(null, null, `/${path}`);
  }
};

export const setPageTitle = () => {
  const { t } = i18n.global;
  document.title = t("pageTitle");
};
