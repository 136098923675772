<template>
  <div class="loading">
    <font-awesome-icon class="loading__icon" :icon="['fas', 'spinner']" spin />
  </div>
</template>

<style lang="sass" scoped>
@import "../../styles/variables"

.loading
  display: flex
  justify-content: center
  padding: 2rem

  &__icon
    font-size: 3rem
    color: $colorMain
</style>
