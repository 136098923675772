<template>
  <SearchForm />
  <div>
    <template v-if="loading">
      <Loading />
    </template>
    <template v-else>
      <template v-if="!error">
        <template v-if="selectedItem === null">
          <ItemSelector :data="data" :select-item="selectItem" />
        </template>
        <div v-else>
          <Item
            :data="selectedItem"
            :multiple="data.length > 1"
            :back-to-selection="backToSelection"
          />
        </div>
      </template>
      <template v-else>
        <p
          style="word-break: break-word"
          v-html="$t('noResultsFound', { id: trackingId })"
        />
      </template>
    </template>
  </div>
</template>

<script>
import Item from "./Item";
import Loading from "../shared/Loading";
import SearchForm from "../shared/SearchForm";
import ItemSelector from "./ItemSelector";
import API from "@/api";

export default {
  name: "Tracking",
  components: { SearchForm, Loading, Item, ItemSelector },
  data() {
    return {
      loading: true,
      trackingId: this.$route.params.id,
      data: null,
      selectedItem: null,
      error: false,
    };
  },
  async mounted() {
    await this.getAckHistory();
  },
  methods: {
    getAckHistory: async function () {
      try {
        const res = await API.getAckHistory(this.trackingId);

        this.data = res.data;
        if (this.data.length === 1) {
          this.selectedItem = res.data[0];
        }
      } catch {
        this.error = true;
      } finally {
        this.loading = false;
      }
    },
    selectItem: function (index) {
      this.selectedItem = this.data[index];
    },
    backToSelection: function () {
      this.selectedItem = null;
    },
  },
};
</script>
