import { createRouter, createWebHistory } from "vue-router";
import TrackingView from "../views/TrackingView";
import HomeView from "../views/HomeView";
import { getCookieValue } from "../utils/cookies";
import { getTrackingPath, setPageTitle } from "../utils/language";

const getCorrectPath = () => {
  const langCookie = getCookieValue("lang");
  const trackingPath = getTrackingPath(langCookie);

  if (trackingPath) {
    return "/" + trackingPath;
  }

  return "/seuranta";
};

const routes = [
  {
    path: "/seuranta/",
    name: "HomeView",
    component: HomeView,
    alias: ["/uppfoljning/", "/tracking/"],
  },
  {
    path: "/seuranta/:id",
    name: "TrackingView",
    component: TrackingView,
    alias: ["/uppfoljning/:id", "/tracking/:id"],
  },
  {
    path: "/",
    redirect: getCorrectPath(),
    hidden: true,
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
    hidden: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  setPageTitle();
  next();
});

export default router;
