<template>
  <div id="app">
    <Header />
    <router-view :key="$route.fullPath" />
  </div>
</template>

<script>
import Header from "./components/Header";
import { getCookieValue } from "./utils/cookies";
import {
  getLocale,
  langOptions,
  setTrackingPath,
  setPageTitle,
} from "./utils/language";

export default {
  name: "App",
  components: {
    Header,
  },
  async mounted() {
    await this.$router.isReady();

    const firstPath = location.pathname.split("/")[1];
    const langCookie = getCookieValue("lang");
    const localeFromPath = getLocale(firstPath);

    if (langOptions.some((lang) => lang.locale === langCookie)) {
      this.$i18n.locale = langCookie;
      const trackingId = this.$route.params.id;
      setTrackingPath(trackingId, langCookie);
    } else if (localeFromPath) {
      this.$i18n.locale = localeFromPath;
    }

    setPageTitle();
    const url = this.$route.hash
      ? this.$route.path + encodeURIComponent(this.$route.hash)
      : this.$route.path;

    await this.$router.push(url);
  },
};
</script>

<style lang="sass">
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600&display=swap")
@import "styles/variables"

body
  margin: 0 auto
  font-family: "Montserrat", sans-serif
  font-size: 16px
  background: $colorBackground
</style>
