<template>
  <div class="track-item" :class="{ 'track-item--current': isCurrent }">
    <div v-if="item.ackTime" class="track-item__timestamp">
      <span>{{ parseTime(item.ackTime) }}</span>
    </div>
    <div class="track-item__desc">
      <span>{{ parseTrackingDescription(item.trackingPageDescriptions) }}</span>
    </div>
    <div v-if="item.location" class="track-item__timestamp">
      <span>{{ item.location }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "Event",
  props: ["item", "isCurrent", "parseTime", "parseTrackingDescription"],
};
</script>

<style lang="sass" scoped>
.track-item
  font-size: 16px
  padding: 10px 0

  &--current
    padding: 0 0 32px

  &__desc
    padding: 3px 0
    font-size: 16px
    font-weight: 500

  &__timestamp
    font-size: 15px
    font-weight: 300
</style>
