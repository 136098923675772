import axios from "axios";

const url = process.env.VUE_APP_API_URL;

/**
 * Get ack history for given document ID.
 *
 * @param {string} document_id Document ID.
 * @returns {Promise} Given document IDs ack history.
 */
export const getAckHistory = async (document_id) => {
  return await axios.get(url, {
    params: {
      document_id,
    },
  });
};
